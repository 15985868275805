<template>
  <div>
    <div v-for="item in list" :key="item.permId" class="text item">
      <el-link type="info" class="vg_cursor_hander" @click="jumpList(item.route, item.permId)">{{ item.moudlename }}</el-link>
    </div>
  </div>
</template>

<script>
import { get } from '@api/request';
import { sconAPI } from '@/api/modules/scon';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'RelationList',
  props: {
    scon: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      list: [
        { permId: 111, moudlename: '客户信息', route: '/cust_list' },
        { permId: 124, moudlename: '成品信息', route: '/prod_list' },
        { permId: 127, moudlename: '成品采购', route: '/podr_list' },
        { permId: 128, moudlename: '材料采购', route: '/modr_list' },
        { permId: 130, moudlename: '出运信息', route: '/tran_list' },
        { permId: 181, moudlename: '销售合同变更单', route: '/sconorder_list' }
      ]
    };
  },
  computed: {
    sconForm() {
      return this.scon;
    }
  },
  created() {
    // this.getSconInfo();
  },
  methods: {
    // 跳转
    jumpList(val, val1) {
      if (val1 === 111) {
        this.jump(val, { perm_id: val1, cust_id: this.sconForm.cust_id });
      } else if (val1 === 124) {
        let temp = [];
        this.sconForm.scon_prod_list.forEach(item => {
          temp.push(item.prod_id);
        });
        let str = temp.join(',');
        this.jump(val, { perm_id: val1, prod_ids: str });
      } else if (val1 === 128 || val1 === 127) {
        this.jump(val, { perm_id: val1, scon_no: this.sconForm.scon_no });
      } else if (val1 === 181) {
        this.jump(val, { perm_id: val1, scon_no: this.sconForm.scon_no });
      } else {
        this.jump(val, { perm_id: val1, scon_nos: this.sconForm.scon_no });
      }
    },
    //获取表单信息
    getSconInfo(val) {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      get(sconAPI.getSconById, { scon_id: props.form_id })
        .then(res => {
          if (res.data.code === 0) {
            this.sconForm = res.data.data.form;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}
</style>
