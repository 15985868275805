<template>
  <div class="sconProdList">
    <!--    <div class="vd_search_group">-->
    <!--      <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" label-width="120px">-->
    <!--        <el-row>-->
    <!--          <el-col :md="8">-->
    <!--            <el-form-item label="中文规格:">-->
    <!--              <el-input size="small" v-model.trim="searchForm.prod_spec" clearable placeholder="请填写中文规格"> </el-input>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->
    <!--          <el-col :md="8">-->
    <!--            <el-form-item label="部件名称:">-->
    <!--              <el-input size="small" v-model.trim="searchForm.prod_partno" clearable placeholder="请填写部件名称"> </el-input>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->
    <!--          <el-col :md="8">-->
    <!--            <el-form-item label="客户货号:">-->
    <!--              <el-input size="small" v-model.trim="searchForm.prod_cust_no" clearable placeholder="请填写客户货号"> </el-input>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->
    <!--        </el-row>-->
    <!--        <el-row>-->
    <!--          <el-col :md="8">-->
    <!--            <el-form-item label="中文名称:">-->
    <!--              <el-input size="small" v-model.trim="searchForm.prod_name" clearable placeholder="请填写中文名称"> </el-input>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->
    <!--          <el-col :md="16">-->
    <!--            <el-form-item label="供应商简称:">-->
    <!--              <el-select size="small" filterable v-model="searchForm.supp_id" placeholder="请选择供应商简称">-->
    <!--                <el-option v-for="item in suppList" :key="item.supp_id" :label="item.supp_abbr" :value="item.supp_id"> </el-option>-->
    <!--              </el-select>-->
    <!--            </el-form-item>-->
    <!--            <el-form-item>-->
    <!--              <el-button size="small" type="primary" icon="el-icon-search" @click="initData()" class="vg_ml_16">查询 </el-button>-->
    <!--              <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->
    <!--        </el-row>-->
    <!--      </el-form>-->
    <!--    </div>-->
    <el-row justify="space-between">
      <el-col :md="12">
        <el-button type="primary" plain size="small" :disabled="disableFlag" @click="generateConfirm">生成</el-button>
        <el-button type="danger" plain size="small" @click="generateCancel">取消</el-button>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
      </el-col>
      <el-col :md="12">
        <!-- <span>关键字搜索：</span>
        <el-input
            v-model="searchContent"
            placeholder="请输入匹配关键字"
            size="medium"
            style="width:200px;margin-bottom:10px;" 
            @focus="focusInput"
            @blur="blurInput" :class="{aa:active,bb:!active}"
          >
        </el-input> -->
      </el-col>
    </el-row>
    <div class="vg_mb_16"></div>
    <el-table :data="tableData" border @selection-change="handleSelectionChange" v-loading="loadFlag">
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="48" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="我司货号">
        <template slot-scope="scope">
          <el-input size="small" v-model="tableData[scope.$index].prod_no" disabled maxlength="30" show-word-limit placeholder="暂无我司货号"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="货号后缀">
        <template slot-scope="scope">
          <el-input size="small" v-model="tableData[scope.$index].prod_poststfix" disabled maxlength="2" show-word-limit placeholder="暂无货号后缀"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="部件名称">
        <template v-slot:header>
          <div>
            <span>部件名称</span>
            <el-input clearable v-model="searchForm.prod_partno" size="mini" placeholder="请输入部件名称"></el-input>
          </div>
        </template>
        <template slot-scope="scope">
          <el-input size="small" v-model="tableData[scope.$index].prod_partno" disabled show-word-limit placeholder="暂无部件名称"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="客户货号">
        <template v-slot:header>
          <div>
            <span>客户货号</span>
            <!--            <el-input clearable v-model="searchForm.prod_cust_no" size="mini" placeholder="请输入客户货号"></el-input>-->
            <el-select v-model="searchForm.prod_cust_no" size="mini" multiple filterable show-word-limit placeholder="请选择" clearable>
              <el-option v-for="item in prodCustNoList" :key="item.prod_cust_no" :value="item.prod_cust_no" :label="item.prod_cust_no"></el-option>
            </el-select>
          </div>
        </template>
        <template slot-scope="scope">
          <el-input size="small" v-model="tableData[scope.$index].prod_cust_no" disabled show-word-limit placeholder="暂无客户货号"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="中文名称">
        <template v-slot:header>
          <div>
            <span>中文名称</span>
            <el-input clearable v-model="searchForm.prod_name" size="mini" placeholder="请输入中文名称"></el-input>
          </div>
        </template>
        <template slot-scope="scope">
          <el-input size="small" v-model="tableData[scope.$index].prod_name" disabled show-word-limit placeholder="暂无中文名称"></el-input>
        </template>
      </el-table-column>
      <!--      <el-table-column label="中文规格">-->
      <!--        <template v-slot:header>-->
      <!--          <div>-->
      <!--            <span>中文规格</span>-->
      <!--            <el-input clearable v-model="searchForm.prod_spec" size="mini" placeholder="请输入中文规格"></el-input>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--        <template slot-scope="scope">-->
      <!--          <el-input size="small" v-model="tableData[scope.$index].prod_spec" disabled show-word-limit placeholder="暂无中文规格"></el-input>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column label="需申购数量" width="150">
        <template slot-scope="scope">
          <el-input
            size="small"
            @change="sconPartApplyChange(scope.$index)"
            v-model="tableData[scope.$index].scon_part_apply"
            @input="tableData[scope.$index].scon_part_apply = keep6Decimal(tableData[scope.$index].scon_part_apply)"
            maxlength="10"
            show-word-limit
            placeholder="暂无需申购数量"
          ></el-input>
        </template>
      </el-table-column>
      <!--      <el-table-column label="预计数量">-->
      <!--        <template slot-scope="scope">-->
      <!--          <el-input-->
      <!--            size="small"-->
      <!--            v-model="generatePartForm[scope.$index].scon_part_num"-->
      <!--            disabled-->
      <!--            show-word-limit-->
      <!--            placeholder="暂无预计数量"-->
      <!--          ></el-input>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column label="实际数量">
        <template slot-scope="scope">
          <el-input size="small" v-model="tableData[scope.$index].scon_part_num_reality" show-word-limit disabled placeholder="暂无实际数量"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="已申购数量">
        <template slot-scope="scope">
          <el-input size="small" v-model="tableData[scope.$index].scon_part_applied" disabled show-word-limit placeholder="暂无已申购数量"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="采购单价">
        <template slot-scope="scope">
          <el-input
            size="small"
            disabled
            v-model="tableData[scope.$index].prod_price"
            @input="tableData[scope.$index].prod_price = helper.keepTNum1(tableData[scope.$index].prod_price)"
            @blur="tableData[scope.$index].prod_price = helper.calcPrice(tableData[scope.$index].prod_price, 4, 10000)"
            maxlength="7"
            show-word-limit
            placeholder="暂无采购单价"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="供应商简称" width="120">
        <template v-slot:header>
          <div>
            <span>供应商简称</span>
            <el-select v-model="searchForm.supp_id" size="mini" filterable show-word-limit placeholder="请选择供应商简称" clearable>
              <el-option v-for="item in suppList" :key="item.supp_id" :value="item.supp_id" :label="item.supp_abbr"></el-option>
            </el-select>
          </div>
        </template>
        <template slot-scope="scope">
          <!--@visible-change="suppChange(generatePartForm[scope.$index].supp_id, scope.$index)"-->
          <el-select size="small" filterable v-model="tableData[scope.$index].supp_id" placeholder="请选择供应商简称">
            <el-option v-for="item in suppList" :key="item.supp_id" :label="item.supp_abbr" :value="item.supp_id"> </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="是否主产品" width="150">
        <template slot-scope="scope">
          <div style="display: flex">
            <el-radio v-model="tableData[scope.$index].prod_mainsup" label="1">是</el-radio>
            <el-radio v-model="tableData[scope.$index].prod_mainsup" label="0">否</el-radio>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { sconAPI } from '@api/modules/scon';
import { podrAPI } from '@/api/modules/podr';
import { suppAPI } from '@api/modules/supp';
import { BigNumber } from 'bignumber.js';
import { keep6Decimal } from '@assets/js/regExUtil';

export default {
  name: 'SconPartList',
  props: {
    // generatePartForm:{
    //   type:Array,
    //   required:true
    // },
    sconId: {
      type: Number,
      required: true
    },
    sconNo: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialogVisible: false,
      selectionsList: [],
      prodUnitList: [],
      value1: '是',
      value2: '否',
      flagShow: true,
      suppList: [],
      numFlag: 0,
      oldNum: 0,
      newNum: 0,
      disableFlag: false,
      searchContent: '',
      active: true,
      generatePartForm: [],
      loadFlag: true,
      searchForm: {
        prod_spec: null,
        prod_partno: null,
        prod_cust_no: [],
        prod_name: null,
        supp_id: null
      },
      prodCustNoList: []
    };
  },
  computed: {
    tableData() {
      return this.generatePartForm.filter(
        x =>
          (!this.searchForm.prod_spec || x.prod_spec.toLowerCase().indexOf(this.searchForm.prod_spec.toLowerCase()) > -1) &&
          (!this.searchForm.prod_partno || x.prod_partno.toLowerCase().indexOf(this.searchForm.prod_partno.toLowerCase()) > -1) &&
          (this.searchForm.prod_cust_no.length === 0 ||
            this.searchForm.prod_cust_no.some(prodCustNo => x.prod_cust_no.toLowerCase() === prodCustNo.toLowerCase())) &&
          (!this.searchForm.prod_name || x.prod_name.toLowerCase().indexOf(this.searchForm.prod_name.toLowerCase()) > -1) &&
          (!this.searchForm.supp_id || x.supp_id === this.searchForm.supp_id)
      );
    }
  },
  created() {
    this.initData();
  },
  methods: {
    keep6Decimal,
    initData() {
      this.loadFlag = true;
      this.getScons();
      this.getSupp();
    },
    //获取供应商信息
    getSupp() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //获取数据
    getScons() {
      get(sconAPI.getSconPartBySconId, {
        scon_id: this.sconId
      })
        .then(res => {
          if (res.data.code === 0) {
            this.generatePartForm = res.data.data.list;
            this.generatePartForm.forEach(item => {
              item.prod_mainsup = item.prod_mainsup + '';
            });
            this.prodCustNoList = [...new Map(this.generatePartForm.map(item => [item.prod_cust_no, item])).values()];
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    handleSelectionChange(selection) {
      this.selectionsList = selection;
      // this.selectRows()
    },
    // 供应商变化时修改
    suppChange(val, val1) {
      this.numFlag++;
      if (this.numFlag === 2) {
        this.newNum = val;
        this.numFlag = 0;
        this.dataChange(val1);
      } else if (this.numFlag === 1) {
        this.oldNum = val;
      }
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        prod_spec: null,
        prod_partno: null,
        prod_cust_no: null,
        prod_name: null,
        supp_id: null
      };
      this.loadFlag = true;
      this.initData();
    },
    // 添加数据
    dataChange(val) {
      if (this.oldNum !== this.newNum) {
        let temp = JSON.parse(JSON.stringify(this.generatePartForm[val]));
        temp.supp_id = this.oldNum;
        temp.prod_mainsup = '0';
        this.generatePartForm.splice(val + 1, 0, temp);
      }
    },
    // 限制数量
    sconPartApplyChange(val) {
      //修改需申购数量保留两位小数 有小数位进1
      this.tableData[val].scon_part_apply = Number(new BigNumber(this.tableData[val].scon_part_apply).toFixed(2, 0));
      if (Number(this.tableData[val].scon_part_num_reality) - Number(this.tableData[val].scon_part_applied) < Number(this.tableData[val].scon_part_apply)) {
        this.tableData[val].scon_part_apply = null;
        this.$message.warning('需申购数量不能大于预计数量减去已申购数量');
      }
    },
    // 取消生成
    generateCancel() {
      this.$emit('generateCancel');
    },
    // 确认生成
    generateConfirm() {
      if (this.selectionsList.length > 0) {
        // let temp = 0;
        // this.selectionsList.forEach(item=>{
        //   if(item.prod_mainsup === '1'){
        //     temp++
        //   }
        // })
        // if(temp>1){
        //   this.$message.warning('只能生成一个主厂')
        // }else{
        let appliFlag = false;
        let appliIs0Flag = false;
        this.selectionsList.forEach(item => {
          if (Number(item.scon_part_apply) === 0) {
            appliIs0Flag = true;
          }
          if (Number(item.scon_part_num_reality) === Number(item.scon_part_applied)) {
            appliFlag = true;
          }
        });
        if (appliFlag) {
          return this.$message.warning('申购已完成');
        }
        if (appliIs0Flag) {
          return this.$message.warning('需申购数量为0！');
        }
        let appliFlagC = false;
        this.selectionsList.forEach(item => {
          if (!item.supp_id) {
            appliFlagC = true;
          }
        });
        if (appliFlagC) {
          return this.$message.warning('请选择供应商简称后生成');
        }
        // let idFlag = false;
        // for(let i=0;i<this.selectionsList.length;i++){
        //   for(let j=0;j<this.selectionsList.length;j++){
        //     if(this.selectionsList[i].scon_part_id === this.selectionsList[j].scon_part_id){
        //       idFlag = true;
        //     }
        //   }
        // }
        // if(idFlag){
        //   return this.$message.warning('')
        // }
        this.$confirm('确定生成?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            let temp = {};
            temp.scon_id = this.sconId;
            temp.scon_part_list = this.selectionsList;
            post(podrAPI.addPodr, temp)
              .then(res => {
                if (res.data.code === 0) {
                  this.$message({
                    type: 'success',
                    message: '生成成功'
                  });
                  const permId = 127;
                  setTimeout(() => {
                    this.jump('/podr_list', { perm_id: permId, scon_no: this.sconNo });
                  });
                  // res.data.data.form_id.forEach(id => {
                  //   console.log(id);
                  //   this.$router.push({
                  //     path: '/podr_edit',
                  //     query: {
                  //       key: this.$UrlEncode.encode(
                  //         JSON.stringify({
                  //           perm_id: permId,
                  //           form_id: id
                  //         })
                  //       )
                  //     }
                  //   });
                  //   /*this.jump('/podr_edit', {
                  //     key: this.$UrlEncode.encode(
                  //       JSON.stringify({
                  //         perm_id: permId,
                  //         form_id: id
                  //       })
                  //     )
                  //   });*/
                  // });
                  this.generateCancel();
                } else {
                  let mg = res.data.msg;
                  let tp = 'error';
                  this.$message({ message: mg, type: tp });
                }
              })
              .catch(() => {});
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消生成'
            });
          });
        // }
      } else {
        this.$message.warning('至少选择一条数据');
      }
    },
    blurInput() {
      this.active = true;
    },
    focusInput() {
      this.active = false;
    },
    selectRows() {
      let temp = false;
      for (let i = 1; i < this.selectionsList.length; i++) {
        if (this.selectionsList[0].supp_id !== this.selectionsList[i].supp_id) {
          temp = true;
        } else {
          temp = false;
        }
      }
      if (temp === true) {
        this.disableFlag = true;
      } else {
        this.disableFlag = false;
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
