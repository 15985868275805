<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" class="addAndEdit" type="border-card">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1 @isShow="isShow" ref="childTab1" @setSconForm="setSconForm" />
      </el-tab-pane>
      <el-tab-pane label="审批流转" name="second" :key="'second'" :lazy="true">
        <childTab3 :isShow="isSho" @draftState="draftState" @jumpBackToDocumentInformation="changeTab" />
      </el-tab-pane>
      <el-tab-pane label="相关文档" name="third" :key="'third'">
        <childTab2 />
      </el-tab-pane>
      <el-tab-pane label="关联信息" name="fourth" :key="'fourth'">
        <childTab4 :scon="sconForm" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import SconEditMain from './TabChild/SconEditMain.vue';
import SconEditAbout from './TabChild/SconEditAbout.vue';
import SconEditAppr from './TabChild/SconEditAppr.vue';
import RelationList from './TabChild/RelationList.vue';

export default {
  name: 'sconEdit',
  components: {
    childTab1: SconEditMain,
    childTab2: SconEditAbout,
    childTab3: SconEditAppr,
    childTab4: RelationList
  },
  data() {
    return {
      activeName: 'first',
      isSho: true,
      sconForm: {}
    };
  },
  created() {},
  methods: {
    isShow(val) {
      this.isSho = val;
    },
    draftState() {
      this.$refs.childTab1.initData();
    },
    setSconForm(val) {
      this.sconForm = val;
    },
    changeTab() {
      this.activeName = 'first';
      this.draftState();
    }
  }
};
</script>

<style lang="scss" scoped>
.addAndEdit > ::v-deep .el-tabs__content {
  height: calc(100vh - 179px);
  overflow-y: auto;
  padding-top: 0;
}
</style>
